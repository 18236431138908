import axios from 'axios';
import { APTOS_CONFIG, MOVE_VALUE } from 'data/constant';

// Define types for the response structure
interface CoinData {
	data: {
		coin: {
			value: string;
		};
	};
}

export const getAptosBalance = async (address: string): Promise<string> => {
	try {
		const response = await axios.get<CoinData>(
			`${APTOS_CONFIG.rpcUrl}/accounts/${address}/resource/0x1::coin::CoinStore%3C0x1::aptos_coin::AptosCoin%3E`,
		);

		if (response.data.data.coin.value === '0') {
			return '0';
		}

		return (parseInt(response.data.data.coin.value) / MOVE_VALUE)
			.toFixed(3)
			.toString();
	} catch (error) {
		return '0';
	}
};

export const fetchAgentPoolUserBalance = async (
	contractAddress: string,
	accountAddress: string,
) => {
	const requestData = {
		function: `${contractAddress}::agent_pool::balanceOf`,
		type_arguments: [],
		arguments: [accountAddress],
	};

	try {
		const response = await axios.post(
			APTOS_CONFIG.rpcUrl + '/view',
			requestData,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);

		if (
			response.data.length &&
			(response.data[0] === '0' || response.data[0] === 0)
		)
			return '0';
		else {
			return `${parseInt(response.data[0]) / 100000000}`;
		}
	} catch (error) {
		return '0';
	}
};
