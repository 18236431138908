import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Vault, VAULTS } from 'data/Vaults';

type VaultState = Vault & { exchange: string; network?: string };

const initialState: VaultState = {
	...VAULTS['Move-EVM'][0],
	exchange: 'Movement-labs',
	network: undefined,
};

export const vaultSlice = createSlice({
	name: 'vault',
	initialState,
	reducers: {
		changeVault: (
			state: Draft<VaultState>,
			action: PayloadAction<Vault>,
		) => {
			state.exchange = action.payload.exchanger[0].name;
			state.network = action.payload.exchanger[0]?.network
				? action.payload.exchanger[0]?.network[0]?.name
				: undefined;
			Object.assign(state, action.payload);
		},
		changeExchange: (
			state: Draft<VaultState>,
			action: PayloadAction<string>,
		) => {
			state.exchange = action.payload;
		},
		changeNetwork: (
			state: Draft<VaultState>,
			action: PayloadAction<string>,
		) => {
			state.network = action.payload;
		},
	},
});

export const vaultActions = vaultSlice.actions;
const vaultReducer = vaultSlice.reducer;
export default vaultReducer;
