export const CONSTANT = {
	testNet: 'testnet',
};

export const TEST_NET_BASE_URL = 'https://testnet.rndm.io';

export const TEST_NET_URL = `${TEST_NET_BASE_URL}?network=movement_porto`;

export const APTOS_CONFIG = {
	id: '177',
	rpcUrl: 'https://aptos.testnet.porto.movementlabs.xyz/v1',
	name: 'testnet',
};
export const MOVE_VALUE = 100000000;
