import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { Network } from 'config';
import { _chains } from '@rainbow-me/rainbowkit/dist/config/getDefaultConfig';
import { isTestNet } from 'utils/isTestNet';

export const mevmChainConfig = {
	id: 30732,
	name: Network['Move-EVM'],
	nativeCurrency: {
		name: 'Move',
		symbol: 'MOVE',
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ['https://mevm.devnet.imola.movementlabs.xyz/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Movement Devnet',
			url: 'https://explorer.devnet.imola.movementlabs.xyz',
		},
	},
};

export const baseNetwork = {
	id: 8453,
	name: Network.Base,
	nativeCurrency: {
		name: 'ETH',
		symbol: 'ETH',
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ['https://mainnet.base.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Base',
			url: 'https://basescan.org',
		},
	},
};

export const avaxNetwork = {
	id: 43114,
	name: 'Avalanche',
	nativeCurrency: {
		name: 'Avalanche',
		symbol: 'AVAX',
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ['https://api.avax.network/ext/bc/C/rpc'],
		},
	},
	blockExplorers: {
		default: {
			name: 'SnowTrace',
			url: 'https://snowtrace.io',
		},
	},
};

export const bscNetwork = {
	id: 56,
	name: Network['Binance-Smart-Chain'],
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ['https://bsc-dataseed.binance.org/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'BscScan',
			url: 'https://bscscan.com',
		},
	},
};

const testNet: _chains = [mevmChainConfig];
const mainNet: _chains = [baseNetwork, avaxNetwork, bscNetwork];

export const wagmiConfig = getDefaultConfig({
	appName: 'RNDM',
	projectId: 'YOUR_PROJECT_ID',
	chains: isTestNet() ? testNet : mainNet,
	ssr: false,
});
