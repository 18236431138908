import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import IndexSaga from './Index-Saga';
import appReducer from 'store/app';
import vaultReducer from 'store/vaults';

const rootReducer = combineReducers({
	app: appReducer,
	vault: vaultReducer,
});

const sagaMiddleware = createSagaMiddleware();

// attach saga middleware to redux store
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware),
});

sagaMiddleware.run(IndexSaga);

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store };
export type { RootState, AppDispatch };
