import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ChainNames } from 'config';

export type AppState = {
	selectedNetwork: ChainNames;
	isDrawerOpen: boolean;
	loader: {
		loading: boolean;
		message: string;
	};
	isWalletOpen: boolean;
};

const initialState: AppState = {
	selectedNetwork: 'Move-Porto',
	isDrawerOpen: false,
	loader: {
		loading: true,
		message: '',
	},
	isWalletOpen: false,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSelectedChain: (
			state: Draft<AppState>,
			action: PayloadAction<ChainNames>,
		) => {
			state.selectedNetwork = action.payload;
		},
		setLoading: (
			state: Draft<AppState>,
			action: PayloadAction<{ loading: boolean; message?: string }>,
		) => {
			state.loader = {
				loading: action.payload.loading,
				message: action.payload.message || 'Please Wait...',
			};
		},
		openDrawer: (
			state: Draft<AppState>,
			action: PayloadAction<boolean>,
		) => {
			state.isDrawerOpen = action.payload;
		},
		openWallet: (
			state: Draft<AppState>,
			action: PayloadAction<boolean>,
		) => {
			state.isWalletOpen = action.payload;
		},
	},
});

export const appActions = appSlice.actions;
const appReducer = appSlice.reducer;

export default appReducer;
