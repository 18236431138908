import React from 'react';
import { styled } from '@mui/system';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp';

const CustomButton = styled(Button)({
	'&.MuiButton-root': {
		border: 'none',
		boxShadow: 'none',
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
});

const CustomMenuItem = styled(MenuItem)({
	'&.MuiMenuItem-root': {
		'&:hover': {
			backgroundColor: 'transparent',
			color: 'inherit', // Keeps the text color as is
		},
	},
});

type ItemType = { label: string; value: string };

const TextDropdown: React.ComponentType<{
	datasource: ItemType[];
	value: string;
	onChange: (value: string) => void;
}> = ({ onChange, value, datasource }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (text: string) => {
		onChange(text);
		handleClose();
	};

	return (
		<div>
			<CustomButton
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				{value || 'Select'}
				{anchorEl ? <ArrowUpIcon /> : <ArrowDropDownIcon />}
			</CustomButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{datasource.map((d) => (
					<CustomMenuItem
						onClick={() => handleMenuItemClick(d.value)}
						key={d.label}
					>
						{d.label}
					</CustomMenuItem>
				))}
			</Menu>
		</div>
	);
};

export default TextDropdown;
