import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

export const NotServedModal: React.ComponentType<{
	open: boolean;
	onClose?(): void;
}> = ({ open, onClose }) => {
	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={() => onClose?.()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'We are sorry...'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Typography variant={'body2'} color={'#555'}>
							We are sorry that we cannot offer services in your
							region at this time. Our platform is currently
							restricted in the following regions:
						</Typography>
						<br />
						<Typography
							variant={'body2'}
							color={'#222'}
							fontWeight={'bold'}
						>
							Belarus, Canada, Cuba, Iran, North Korea, Russia,
							Syria, Ukraine, United States of America, United
							Kingdom.
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => onClose?.()} autoFocus>
						I accept
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
