import { useCallback, useEffect, useState } from 'react';
import { APTOS_CONFIG } from 'data/constant';
import { useAptosWallet } from '@razorlabs/wallet-kit';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import axios from 'axios';

export const useAptosContractBalance = () => {
	const [balance, setBalance] = useState('0');
	const { account, connected, signAndSubmitTransaction } = useAptosWallet();
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.name === appVault.exchange,
	);

	const fetchAgentPoolBalance = useCallback(async () => {
		const requestData = {
			function:
				(selectedExchange?.aptosContractAddress || '') +
				'::agent_pool::balanceOf',
			type_arguments: [],
			arguments: [account?.address],
		};

		try {
			const response = await axios.post(
				APTOS_CONFIG.rpcUrl + '/view',
				requestData,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);

			if (
				response.data.length &&
				(response.data[0] === '0' || response.data[0] === 0)
			)
				setBalance('0');
			else {
				setBalance(`${parseInt(response.data[0]) / 100000000}`);
			}
		} catch (error) {
			//
		}
	}, [account?.address, selectedExchange?.aptosContractAddress]);

	useEffect(() => {
		fetchAgentPoolBalance();
	}, [fetchAgentPoolBalance]);

	return {
		/*aptosBalance: (
			parseFloat((aptosBalance ?? 0)?.toString()) / MOVE_VALUE
		).toFixed(2),*/
		//aptosContractBalance: connected ? balance : '0',
		aptosAccount: account,
		isAptosConnected: connected,
		signAndSubmitTransaction,
	};
};
