import React from 'react';
import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { TotalTvl } from 'data/Vaults';

export const VaultGraph: React.ComponentType<{
	height?: number;
	dataSource: TotalTvl[];
}> = ({ height, dataSource }) => {
	return (
		<ResponsiveContainer width="100%" height={height || 50}>
			<AreaChart
				data={dataSource}
				margin={{ top: 10, left: 0, bottom: 0 }}
			>
				<defs>
					<linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
						<stop
							offset="50%"
							stopColor="#235EE1"
							stopOpacity={0.7}
						/>
						<stop
							offset="100%"
							stopColor="#235ee1"
							stopOpacity={0}
						/>
					</linearGradient>
				</defs>
				<XAxis
					dataKey="month"
					tick={{ fill: '#b3b3bb' }}
					style={{ fontSize: 13 }}
					axisLine={{ stroke: '#b3b3bb' }}
				/>
				<YAxis
					tick={{ fill: '#b3b3bb' }}
					style={{ fontSize: 13 }}
					axisLine={{ stroke: '#b3b3bb' }}
					label={{
						value: 'in million',
						angle: -90,
						position: 'insideLeft',
						style: {
							textAnchor: 'middle',
							fill: '#b3b3bb',
							fontSize: 13,
						},
					}}
				/>

				<Area
					type="monotone"
					dataKey="Value"
					stroke="#235EE1"
					fill="url(#colorValue)"
				/>
				<Tooltip />
			</AreaChart>
		</ResponsiveContainer>
	);
};
