import React from 'react';
import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const CustomSelect = styled(Select)(() => ({
	backgroundColor: '#E6E6E9',
	padding: '5px',
	width: '100%',
	'& .MuiSelect-select': {
		display: 'flex',
		alignItems: 'center',
		fontSize: '1.25rem',
		color: '#000',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'&.Mui-disabled .MuiSelect-icon': {
		display: 'none',
	},
}));

const StyledMenuItem = styled(MenuItem)``;

const CustomDropdown: React.FC<{
	value: string;
	image: string;
	dataSource: {
		name: string;
		image: string;
		value: string;
	}[];
	onChange?(item: SelectChangeEvent<any>): void;
}> = ({ value, onChange, dataSource, image }) => {
	const selectedItem = dataSource.find(
		(d) => d.value.toLowerCase() === value.toLowerCase(),
	);

	return (
		<CustomSelect
			value={value}
			onChange={onChange}
			displayEmpty
			disabled={dataSource.length < 2}
			renderValue={() => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<img
						src={selectedItem?.image}
						height={20}
						width={20}
						alt={selectedItem?.name}
						style={{ marginRight: '8px' }}
					/>
					<Typography variant="body1" fontSize={20}>
						{selectedItem?.name}
					</Typography>
				</div>
			)}
		>
			{dataSource.map((d) => (
				<StyledMenuItem value={d.value} key={d.value}>
					<ListItemIcon>
						<img
							src={d.image}
							height={20}
							width={20}
							alt={d.name}
						/>
					</ListItemIcon>
					<ListItemText primary={d.name} />
				</StyledMenuItem>
			))}
		</CustomSelect>
	);
};

export default CustomDropdown;
