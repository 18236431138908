import * as React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Landing } from 'pages';
import { Navbar } from 'components';
import TermsAndCondition from 'pages/TermsAndCondition';
import PrivacyAndPolicy from 'pages/Privacy and policy';
import LoadingDialog from 'components/Loader';

const DApp: React.ComponentType = () => {
	return (
		<Router>
			<div>
				<LoadingDialog />
				<>
					<Navbar />
					<Switch>
						<Route path="/terms-and-conditions">
							<TermsAndCondition />
						</Route>
						<Route path="/privacy">
							<PrivacyAndPolicy />
						</Route>
						<Route path="/">
							<Landing />
						</Route>
					</Switch>
				</>
			</div>
		</Router>
	);
};

export default DApp;
