import React from 'react';
import { Vault } from 'data/Vaults';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { vaultActions } from 'store/vaults';
import { VaultGraph } from 'pages/Landing/components/VaultGraph';
import TextDropdown from 'pages/Landing/components/TextDropdown';
import { networkConfig } from 'config';

const Card = styled('div')`
	border-radius: 8px;
	overflow: hidden;
	background-color: white;
	padding: 30px;
`;

const GraphWrapper = styled('div')`
	background: #e6e6e966;
	padding: 10px;
	border-radius: 10px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const TestnetWrapper = styled(Row)`
	background: #000;
	padding: 4px 10px;
	border-radius: 4px;
`;

const BetweenRow = styled(Row)`
	justify-content: space-between;
`;

const Header = styled(Row)`
	width: 100%;
	justify-content: space-between;
`;

const Body = styled('div')`
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 20px;
	width: 100%;
`;

const Item = styled(Row)`
	background: #e6e6e966;
	justify-content: space-between;
	padding: 15px 10px;
	align-items: flex-start;
	border-radius: 4px;
`;

const Column = styled('div')`
	display: grid;
	grid-template-rows: 1fr 1fr;
	row-gap: 20px;
`;

const Grid = styled('div')`
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	row-gap: 10px;
	column-gap: 10px;
`;

const ImageWrapper = styled('div')`
	padding: 3px;
	border-radius: 4px;
	max-width: 36px;
	max-height: 36px;
	background: #e6e6e9;
`;

export const DashboardCard: React.ComponentType<Vault> = ({ ...props }) => {
	const dispatch = useDispatch();
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedYield =
		selectedVault.name === props.name
			? props.exchanger.find(
					(f) =>
						f.value.toLowerCase() ===
						selectedVault.exchange.toLowerCase(),
			  )
			: props.exchanger[0];
	const { selectedNetwork } = useSelector((state: RootState) => state.app);

	const selectedExchanger = selectedVault.exchanger.find(
		(f) => f.value.toLowerCase() === selectedVault.exchange.toLowerCase(),
	);

	const selectedValue =
		selectedVault.name === props.name
			? selectedExchanger?.name
			: props.exchanger[0].name;

	const onCardClick = (text?: string) => {
		if (props.isActive) {
			if (props.name !== selectedVault.name) {
				dispatch(vaultActions.changeVault(props));
			}
			if (text) {
				dispatch(vaultActions.changeExchange(text));
			}
		}
	};

	const handleDropdownClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	return (
		<Card
			style={{
				opacity: props.isActive ? 1 : 0.5,
			}}
			onClick={() => {
				onCardClick();
			}}
		>
			<Header>
				<Row>
					<img
						src={props.darkLogo}
						alt={props.name}
						width={30}
						height={30}
						style={{
							background: '#235EE1',
							borderRadius: 4,
							padding: 4,
						}}
					/>
					<Box width={8} />
					<Typography
						variant={'body1'}
						fontWeight={500}
						fontSize={20}
					>
						{props.name}
					</Typography>
					<Box width={8} />
					<Typography
						variant={'body1'}
						fontWeight={500}
						fontSize={20}
						color={'#b3b3bb'}
					>
						|
					</Typography>
					<Box width={8} />
					<Typography
						variant={'body1'}
						fontWeight={500}
						fontSize={20}
						color={'#b3b3bb'}
					>
						Agent
					</Typography>
				</Row>

				{networkConfig[selectedNetwork].isTestChain && props.isActive && (
					<TestnetWrapper
						style={{
							background: '#000',
							padding: '4px 10px',
							borderRadius: 4,
						}}
					>
						<Typography
							variant={'subtitle2'}
							color={'#fff'}
							fontWeight={600}
						>
							TESTNET
						</Typography>
					</TestnetWrapper>
				)}

				{!props.isActive && (
					<Row>
						<Typography variant={'subtitle1'} color={'#235EE1'}>
							Coming Soon
						</Typography>
					</Row>
				)}
			</Header>
			<Box height={16} />
			<Body>
				<Column>
					<Item>
						<Typography variant={'subtitle1'}>
							{props.type}
						</Typography>
						<Grid>
							{props.exchanger.map((ex) => (
								<ImageWrapper key={ex.name}>
									<img
										width={30}
										height={30}
										src={ex.image}
										alt={ex.name}
									/>
								</ImageWrapper>
							))}
						</Grid>
					</Item>
					<div>
						<Item
							style={{
								alignItems: 'flex-start',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<Row style={{ alignSelf: 'flex-end' }}>
								<img
									src={'/images/crypto/usdc.png'}
									height={20}
									width={20}
									alt={'USDC'}
								/>
								<Box width={10} />
								<Typography
									variant={'subtitle2'}
									color={'#000'}
								>
									USDC
								</Typography>
							</Row>
							<Box height={20} />
							<Typography
								variant={'h6'}
								style={{ lineHeight: 0.8 }}
								fontWeight={'bold'}
							>
								Total Volume&nbsp;
								{(
									selectedYield?.totalUSDC || 0
								).toLocaleString()}
								&nbsp;(2024)
							</Typography>
							{selectedYield?.tvl && (
								<>
									<Box height={5} />
									<Typography
										variant={'h6'}
										style={{ lineHeight: 0.8 }}
										fontWeight={'bold'}
									>
										Tvl&nbsp;
										{(
											selectedYield?.tvl || 0
										).toLocaleString()}
									</Typography>
								</>
							)}
						</Item>
					</div>
				</Column>
				<GraphWrapper>
					<BetweenRow>
						<Typography
							variant={'subtitle2'}
							color={'#000'}
							fontWeight={500}
						>
							Perp exchange volume
						</Typography>
						<div onClick={handleDropdownClick}>
							<TextDropdown
								datasource={props.exchanger.map((d) => {
									return {
										label: d.name,
										value: d.value,
									};
								})}
								value={selectedValue || ''}
								onChange={(d) => onCardClick(d)}
							/>
						</div>
					</BetweenRow>
					<Box height={20} />
					<VaultGraph
						height={250}
						dataSource={
							(props.name === selectedVault.name
								? selectedExchanger?.totalTvl
								: props.exchanger[0]?.totalTvl) || []
						}
					/>
				</GraphWrapper>
			</Body>
		</Card>
	);
};
