import React, { useState } from 'react';
import styled from '@emotion/styled';
import { VAULTS } from 'data/Vaults';
import { DashboardCard } from 'pages/Landing/components/DashboardCard';
import { Box, Typography } from '@mui/material';
import { DepositPanel } from 'pages/NewDashboard/components/DepositPanel';
import { WithdrawPanel } from 'pages/NewDashboard/components/Withdraw';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 2fr 1fr;
	width: 100%;
	height: 100%;
	padding: 40px;
	column-gap: 40px;
`;

const Grid = styled('div')`
	display: grid;
	grid-template-rows: 1fr 1fr;
	width: 100%;
	height: 100%;
	row-gap: 40px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Text = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
	color: ${({ isActive }) => (isActive ? '#235ee1' : '#b3b3bb')};
	margin-right: 20px;
	font-weight: 600;

	:hover {
		cursor: pointer;
		color: #658ade;
	}
`;

const Withdraw = styled('div')`
	border-radius: 16px;
	background-color: #fff;
	width: 100%;
	height: 100%;
	padding: 40px;
`;

enum Tabs {
	Deposit = 'Deposit',
	WithDraw = 'WithDraw',
}

export const NewDashboard = () => {
	const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Deposit);
	const appState = useSelector((state: RootState) => state.app);

	return (
		<Wrapper>
			<Grid>
				{VAULTS[appState.selectedNetwork].map((d) => (
					<DashboardCard key={d.name} {...d} />
				))}
			</Grid>
			<Withdraw>
				<Row>
					<Text
						isActive={activeTab === Tabs.Deposit}
						onClick={() => setActiveTab(Tabs.Deposit)}
					>
						Deposit
					</Text>
					<Text
						isActive={activeTab === Tabs.WithDraw}
						onClick={() => setActiveTab(Tabs.WithDraw)}
					>
						Withdraw
					</Text>
				</Row>
				<Box height={16} />
				{activeTab === Tabs.Deposit && <DepositPanel />}
				{activeTab === Tabs.WithDraw && <WithdrawPanel />}
			</Withdraw>
		</Wrapper>
	);
};
