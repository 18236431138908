import { useEffect } from 'react';
import { MAINNET, TESTNET } from 'data/depositContract';
import { useAppDispatch } from 'hooks/hooks';
import { ChainNames } from 'config';
import { useLocation } from 'react-router-dom';
import { vaultActions } from 'store/vaults';
import { VAULTS } from 'data/Vaults';
import { useAccount, useDisconnect } from 'wagmi';
import { isTestNet } from 'utils/isTestNet';
import { appActions } from 'store/app';
import { TEST_NET_BASE_URL } from 'data/constant';

export const useNetwork = () => {
	const { disconnect } = useDisconnect();
	const dispatch = useAppDispatch();
	const query = new URLSearchParams(useLocation().search);
	const network = (query.get('network') || '').toLowerCase();
	const networkId = (
		(localStorage.getItem('networkId') as ChainNames) || ''
	).toLowerCase();
	const atvNetwork = isTestNet()
		? TESTNET[network as keyof typeof TESTNET] || 'Move-Porto'
		: MAINNET[network as keyof typeof MAINNET] || 'Avalanche';
	const selectedNetwork = atvNetwork.toLowerCase();
	const { chain, isConnected } = useAccount();

	useEffect(() => {
		if (atvNetwork && chain && atvNetwork !== chain.name && isConnected) {
			disconnect();
		}
	}, [atvNetwork, chain, isConnected, disconnect, selectedNetwork]);

	useEffect(() => {
		// Remove later down the road
		let activeVault;
		if (window.location.origin.includes(TEST_NET_BASE_URL)) {
			activeVault = VAULTS['Move-Porto']?.[0];
			dispatch(appActions.setSelectedChain('Move-Porto'));
		} else {
			activeVault = VAULTS[atvNetwork]?.[0];
			dispatch(appActions.setSelectedChain(atvNetwork));
		}

		if (activeVault?.isActive) {
			dispatch(vaultActions.changeVault(activeVault));
		}

		if (
			networkId !== '' &&
			selectedNetwork !== '' &&
			networkId !== selectedNetwork
		) {
			disconnect();
		}
	}, [dispatch, atvNetwork, disconnect, networkId, selectedNetwork]);
};
