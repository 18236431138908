import React from 'react';
import styled from '@emotion/styled';
import { Vault, VAULTS } from 'data/Vaults';
import { Box, Typography } from '@mui/material';
import { vaultActions } from 'store/vaults';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Card = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>`
	border-radius: 8px;
	width: 100%;
	background: #e6e6e966;
	overflow: hidden;

	:hover {
		cursor: ${({ isActive }) => (isActive ? 'pointer' : 'no-drop')};
	}
`;

const Header = styled(Row)`
	width: 100%;
	background: #e6e6e9;
	padding: 8px;
	justify-content: space-between;
`;

const Body = styled('div')`
	padding: 10px 8px;
	background: #e6e6e966;
`;

export const SmallItemCard = () => {
	const appState = useSelector((state: RootState) => state.app);

	const Item = (vault: Vault) => {
		const dispatch = useDispatch();
		const selectedVault = useSelector((state: RootState) => state.vault);

		return (
			<Card
				isActive={vault.isActive}
				style={{
					opacity: vault.isActive ? 1 : 0.5,
					border: `2px solid ${
						vault.name === selectedVault.name
							? '#235EE1'
							: 'transparent'
					}`,
				}}
				onClick={() => {
					if (vault.isActive) {
						if (vault.name !== selectedVault.name) {
							dispatch(vaultActions.changeVault(vault));
						}
					}
				}}
			>
				<Header>
					<Row>
						<img
							src={vault.logo}
							alt={vault.name}
							width={25}
							height={25}
						/>
						<Box width={8} />
						<Typography variant={'subtitle1'}>
							{vault.name}
						</Typography>
					</Row>
					{!vault.isActive && (
						<Row>
							<Typography variant={'subtitle1'} color={'#235ee1'}>
								Soon
							</Typography>
						</Row>
					)}
				</Header>
				<Body>
					<Typography variant={'subtitle1'}>
						{vault.shortDescription}
					</Typography>
					<Box height={10} />
				</Body>
			</Card>
		);
	};

	return (
		<Wrapper>
			{VAULTS[appState.selectedNetwork].map((d) => (
				<Item key={d.name} {...d} />
			))}
		</Wrapper>
	);
};
