import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { TotalTvl } from 'data/Vaults';

export const TvlGraph: React.ComponentType<{
	toolTips?: boolean;
	height?: number;
	dataSource: TotalTvl[];
}> = ({ toolTips = false, dataSource }) => {
	return (
		<ResponsiveContainer width="100%" height={50}>
			<AreaChart
				data={dataSource} //selectedExchanger?.totalTvl}
				margin={{ top: 10, left: 0, bottom: 0 }}
			>
				<defs>
					<linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
						<stop
							offset="5%"
							stopColor="#8884d8"
							stopOpacity={0.8}
						/>
						<stop
							offset="95%"
							stopColor="#8884d8"
							stopOpacity={0}
						/>
					</linearGradient>
				</defs>
				<Area
					type="monotone"
					dataKey="Value"
					stroke="#8884d8"
					fill="url(#colorValue)"
				/>
				{toolTips && <Tooltip />}
			</AreaChart>
		</ResponsiveContainer>
	);
};
