import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Container = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	min-width: 250px;
	min-height: 200px;
`;

const LoadingDialog = () => {
	const appState = useSelector((state: RootState) => state.app);

	return (
		<Dialog
			open={appState.loader.loading}
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
				},
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(10px)', // This blurs the background
					backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a semi-transparent overlay
				},
			}}
		>
			<Container>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CircularProgress color="primary" />
					<Typography variant="h6" style={{ marginTop: '20px' }}>
						{appState.loader.message || 'Please wait...'}
					</Typography>
				</Box>
			</Container>
		</Dialog>
	);
};

export default LoadingDialog;
