import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { NetworkConfig } from 'config';
import { useHistory } from 'react-router-dom';
import { appActions } from 'store/app';
import { VAULTS } from 'data/Vaults';
import { vaultActions } from 'store/vaults';
import { toast } from 'react-toastify';
import { useDisconnect } from 'wagmi';
import { useAptosWallet } from '@razorlabs/wallet-kit';

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'disabled',
})<{ isSelected?: boolean; disabled?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	margin-right: 20px;
	width: 100%;
	margin-bottom: 8px;
	border-radius: 4px;
	border: 1px solid #d6d6d6;
	cursor: pointer;
	background: ${({ isSelected, disabled }) =>
		!disabled
			? isSelected
				? 'rgb(228, 228, 228)'
				: 'rgb(249, 249, 249)'
			: 'rgb(249, 249, 249)'};

	&:hover {
		background-color: ${({ disabled }) =>
			!disabled ? 'rgb(238, 238, 238)' : 'none'};
	}
`;

const NetworkTitle: React.ComponentType<NetworkConfig> = ({
	label,
	image,
	name,
	path,
	disabled,
	comingSoon,
}) => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { disconnect } = useDisconnect();
	const { disconnect: aptosDisconnect } = useAptosWallet();
	const appState = useSelector((state: RootState) => state.app);

	const handleRedirect = () => {
		const currentUrl = window.location.pathname;
		const searchParams = new URLSearchParams(window.location.search);

		if (searchParams.has('network')) {
			searchParams.set('network', path);
		} else {
			searchParams.append('network', path);
		}

		history.push(`${currentUrl}?${searchParams.toString()}`);
	};

	const onItemSelect = () => {
		const activeVault = VAULTS[name][0];
		if (activeVault.isAptos) {
			disconnect();
			aptosDisconnect();
		}
		handleRedirect();
		dispatch(appActions.setSelectedChain(name));
		if (activeVault.isActive) {
			dispatch(vaultActions.changeVault(activeVault));
		}
	};

	return (
		<StyledBox
			onClick={() => {
				if (comingSoon) {
					toast('Coming soon. Please connect on discord.', {
						type: 'info',
					});
					return;
				}
				if (disabled) {
					toast('Temporary unavailable', {
						type: 'info',
					});
					return;
				}

				if (appState.selectedNetwork !== name) {
					onItemSelect();
				}
			}}
			isSelected={name === appState.selectedNetwork}
			disabled={disabled}
		>
			<img src={image} height={24} />
			<Box
				sx={{
					width: 10,
				}}
			/>
			<Typography
				variant={'caption'}
				textAlign="center"
				color={'#111'}
				fontWeight={500}
			>
				{label}
			</Typography>
		</StyledBox>
	);
};

export { NetworkTitle };
